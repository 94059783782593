:root {
  --warning-bg-color: #ed6c02;
  --warning-color: white;
  --selected: #4caf50;
  --assigned: #42a5f5;
  --error: #d32f2f;
}

.data-grid-custom-asc::after {
  font-family: 'Font Awesome 6 Free';
  content: '\f062';
  font-weight: 900;
}
.data-grid-custom-desc::after {
  font-family: 'Font Awesome 6 Free';
  content: '\f063';
  font-weight: 900;
}
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable
  .MuiDataGrid-iconButtonContainer {
  display: none;
}
.dropzone {
  text-align: center;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
.locker-list .MuiDataGrid-row:hover:not([class^='datagrid_row-disabled']),
.cluster-list .MuiDataGrid-row:hover:not([class^='datagrid_row-disabled']) {
  cursor: pointer;
}
.selectBoxErrorField {
  width: 292px !important;
  margin-right: 14px !important;
  margin-left: 14px !important;
}

.warning {
  background-color: var(--warning-bg-color);
  color: var(--warning-color);
  padding: 5px;
  font-size: 12px;
  display: inline-block;
}
.color-legend {
  width: 32px;
  height: 32px;
  border: 1px solid rgb(224, 224, 224);
}
.own-drawer {
  background-color: #4caf50;
}
.others-drawer {
  background-color: #ff9800;
}
.free-drawer {
  background-color: white;
}
div[class*='own-drawer'],
div[class*='others-drawer'] {
  color: white;
}
.row-disabled {
  position: relative;
  color: rgb(30, 30, 30, 0.7);
}
.row-disabled:hover {
  background-color: rgb(23, 23, 23, 0.1);
  color: rgb(23, 23, 23, 0.9);
}
.row-disabled::before {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  background-color: rgb(23, 23, 23, 0.1);
  color: rgb(23, 23, 23, 0.9);
  z-index: 1;
}
.connected-locker {
  border-left: 10px solid var(--assigned);
}
.autonomous-locker {
  border-left: 10px solid var(--selected);
}
.locker-type-connected {
  background-color: var(--assigned);
}
.locker-type-autonomous {
  background-color: var(--selected);
}
.errorPopup .MuiDialog-container.MuiDialog-scrollPaper {
  width: 100%;
}

.error {
  color: var(--error);
}

.uptime-level.average {
  background-color: var(--warning-bg-color);
  color: #fff;
}
.uptime-level.above-average {
  background-color: var(--selected);
  color: #fff;
}
.uptime-level.bellow-average {
  background-color: var(--error);
  color: #fff;
}

.load-level.average {
  background-color: var(--warning-bg-color);
  color: #fff;
}
.load-level.above-average {
  background-color: var(--selected);
  color: #fff;
}
.load-level.bellow-average {
  background-color: var(--error);
  color: #fff;
}

.full-screen-dialog .MuiPaper-root {
  background-color: transparent;
}
.lockers-map-container {
  background-color: white;
  margin: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  background-color: rgba(241, 128, 23, 0.6);
  text-align: center;
  border-radius: 15px;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
}
.month-picker
  div[role='presentation']
  .PrivatePickersFadeTransitionGroup-root:nth-of-type(2) {
  display: none !important;
}

.unauthenticated-header .language-selector {
  margin-right: 16px;
  margin-left: 0;
  margin-bottom: 0;
}
.unauthenticated-header
  .language-selector
  .Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #0000003b;
}

.unauthenticated-header
  .language-selector
  .MuiOutlinedInput-root.MuiInputBase-root {
  color: white;
}

.locker-in-cluster,
.cluster-locker {
  background-color: rgba(255, 171, 145, 0.8);
}

.locker-deleted {
  background-color: rgba(23, 23, 23, 0.1);
}
.locker-in-cluster:hover {
  background-color: rgba(255, 171, 145, 1) !important;
}

.account-link {
  display: flex;
  text-decoration: none;
  color: white;
}

.locality-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 190px;
}

.awb-ellipsis-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.awb-cell {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

@media (min-width: 1920px) {
  #root {
    max-width: 1920px;
    position: relative;
  }
  .main-header {
    max-width: 1740px;
    right: auto !important;
  }
  .spinner-container {
    position: absolute !important;
    min-height: 100vh;
  }
}
